<template>
  <div class="BlogShow">
    <HeadOrders
      :PathPageFrom="$t('Home')"
      :PathPageCenter="false"
      :PathPageTo="$t('BlogShow')"
    />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="title">{{ BlogsDetails.title }}</h1>
        </div>
        <div class="col-12 mt-4">
          <BlogInformation
            :BlogComments="BlogComments"
            :BlogsDetails="BlogsDetails"
          />
        </div>

        <div class="col-12 mt-4">
          <div class="contnet" v-html="BlogsDetails.content"></div>
        </div>
        <strong>{{ likes }} {{ $t("Like") }}</strong>

        <div class="col-12 mt-4">
          <div class="like-btn">
            <button
              @click="toggleLike"
              :class="{ liked: BlogsDetails.is_liked }"
              class="btn"
            >
              <svg class="icon ml-2" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                />
              </svg>
              {{ $t("likeit") }}
            </button>
            <VerfyLogin v-if="this.$store.state.VerfyLogin == true" />
          </div>
        </div>

        <div class="d-flex mt-4">
          <span
            v-for="item in BlogsDetails.tags"
            class="badge rounded-pill bg-secondary"
            style="margin-right: 7px"
            >{{ item }}</span
          >
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="comment-section mt-5 mb-5">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.7 12.2H29.1V23.9H12.2V26.5C12.2 27.215 12.785 27.8 13.5 27.8H27.8L33 33V13.5C33 12.785 32.415 12.2 31.7 12.2ZM26.5 20V8.3C26.5 7.585 25.915 7 25.2 7H8.3C7.585 7 7 7.585 7 8.3V26.5L12.2 21.3H25.2C25.915 21.3 26.5 20.715 26.5 20Z"
                  fill="#225476"
                />
              </svg>
              <span>
                {{
                  this.$i18n.locale == "ar" ? `اترك تعليقك !` : `left comment !`
                }}
              </span>

              <Form
                ref="commentForm"
                class="comment-form mt-2 mb-2"
                @submit="onSubmit"
                :validation-schema="schema"
              >
                <div
                  class="row form-row mb-2"
                  :class="[userdata ? 'd-none' : '']"
                >
                  <div
                    class="col-md-6"
                    style="background-color: #f3f7f9; height: 45px"
                  >
                    <div class="form-group">
                      <Field
                        name="user_name"
                        type="text"
                        :placeholder="$t('FullName')"
                      />
                      <ErrorMessage name="user_name" class="error-message" />
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>

                <div class="row form-row">
                  <div
                    class="col-sm-10"
                    style="background-color: #f3f7f9; height: 60px"
                  >
                    <div class="form-group">
                      <Field
                        name="comment"
                        :placeholder="$t('comment')"
                        class="p-4 mt-2"
                        style="height: 35px"
                      />
                    </div>
                  </div>
                  <div class="col-sm-2 btn-col" style="height: 60px">
                    <button type="submit" class="submit-btn">
                      {{ $t("Send") }}
                    </button>
                  </div>
                </div>
              </Form>
              <div class="comments-container">
                <div
                  class="comment-card"
                  v-for="item in BlogComments"
                  :key="item.id"
                >
                  <p class="comment-text">{{ item.comment }}</p>
                  <div class="comment-header">
                    <div class="d-flex align-items-center">
                      <img :width="40" :height="40" :src="item.user_image" />
                      <strong>{{ item.user_name }}</strong>
                    </div>
                    <div class="d-flex align-items-center">
                      <div
                        class="comment-actions d-flex"
                        v-if="userdata && userdata.name == item.user_name"
                      >
                        <button
                          class="btn btn-sm btn-primary edit-btn"
                          @click="editComment(item)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-edit"
                          >
                            <path
                              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                            ></path>
                            <path
                              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                            ></path>
                          </svg>
                        </button>
                        <UpdateComment
                          v-if="
                            this.$store.state.updateComment == true &&
                            currentEditedItem === item
                          "
                          :item="currentEditedItem"
                          :currentPage="currentPage"
                          @send-array="handleArrayFromChild"
                        />
                        <button
                          class="btn btn-sm btn-danger delete-btn"
                          @click="deleteComment(item.id)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav aria-label="Page navigation" class="navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="fetchComments(currentPage - 1)"
                    >{{ $t("Previous") }}</a
                  >
                </li>
                <li
                  class="page-item"
                  v-for="page in pagesCount"
                  :key="page"
                  :class="{ active: currentPage === page }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="fetchComments(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === pagesCount }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="fetchComments(currentPage + 1)"
                    >{{ $t("Next") }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
          <router-link
            to="/contact"
            class="col-md-4 d-flex justify-content-center info mb-4 mt-4"
          >
            <img :src="imageSrc" alt="joinFrame" class="w-100" />
            <div class="content">
              <p>
                {{
                  $t(
                    "Start your journey in the business world with confidence and let us be part of your success"
                  )
                }}
              </p>
              <button class="btn-seconde">{{ $t("Free consultation") }}</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { configure } from "vee-validate";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  name: "Blogs Details",
  data() {
    return {
      BlogsDetails: [],
      BlogComments: [],
      currentPage: 1,
      pagesCount: 0,
      userdata: null,
      currentEditedItem: null,
      likes: 0,
      imageSrc: `${process.env.VUE_APP_BASE_URL}/images/static_content/default_frame.png`,
    };
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      user_name: yup.string().optional(),
      comment: yup.string().required(),
    });
    return {
      schema,
    };
  },
  components: {
    HeadOrders: defineAsyncComponent(() =>
      import("@/components/Global/HeadOrders.vue")
    ),
    Share: defineAsyncComponent(() =>
      import("@/views/Client/blogs/SocialShare.vue")
    ),
    UpdateComment: defineAsyncComponent(() =>
      import("@/views/Client/blogs/UpdateComments.vue")
    ),
    VerfyLogin: defineAsyncComponent(() =>
      import("@/views/Client/blogs/VerfyLogin.vue")
    ),
    BlogInformation: defineAsyncComponent(() =>
      import("@/views/Client/blogs/BlogInformation.vue")
    ),
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {
    this.userdata = cookie.get("Userdata");
    this.$store.dispatch("GetBlogsDetails", this.$route.params.id).then((r) => {
      this.BlogsDetails = this.$store.state.BlogsDetails;
      this.likes = this.BlogsDetails["likes"];
    });
    this.fetchComments(this.currentPage);
  },

  methods: {
    fetchComments(page) {
      let blogId = this.$route.params.id;
      this.$store
        .dispatch("GetBlogsComments", { blogId: blogId, page: page })
        .then((r) => {
          this.BlogComments = this.$store.state.BlogComments;
          this.pagesCount = this.$store.state.pagesCount;
          this.currentPage = page;
        });
    },
    async onSubmit(values) {
      let blogId = this.$route.params.id;
      this.userdata = cookie.get("Userdata");
      const formData = {
        user_name: values.user_name || "",
        comment: values.comment,
      };
      formData["blog_id"] = blogId;

      if (this.userdata && cookie.get("Token")) {
        const token = cookie.get("Token");

        await axios
          .post(`user/blogs/comments`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            this.$store
              .dispatch("GetBlogsComments", {
                blogId: blogId,
                page: this.currentPage,
              })
              .then((r) => {
                this.BlogComments = this.$store.state.BlogComments;
                this.pagesCount = this.$store.state.pagesCount;
              });
            notify({
              type: "success",
              title: "إضافة تعليق",
              text: "تم إضافة التعليق بنجاح",
            });
          })
          .catch(function (error) {
            if (error) {
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: error?.response?.data?.message,
              });
            }
          });
      } else {
        await axios
          .post(`user/blogs/comments`, formData)
          .then((res) => {
            this.$store
              .dispatch("GetBlogsComments", {
                blogId: blogId,
                page: this.$store.state.pagesCount,
              })
              .then((r) => {
                this.BlogComments = this.$store.state.BlogComments;
                this.pagesCount = this.$store.state.pagesCount;
              });
            notify({
              type: "success",
              title: "إضافة تعليق",
              text: "تم إرسال التعليق بانتظار موافقة القسم المسؤول",
            });
          })
          .catch(function (error) {
            if (error) {
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: error?.response?.data?.message,
              });
            }
          });
      }
      this.$refs.commentForm.resetForm();
    },
    toggleLike() {
      if (this.userdata) {
        this.BlogsDetails.is_liked = !this.BlogsDetails.is_liked;
        if (this.BlogsDetails.is_liked) {
          this.likes++;
        } else {
          this.likes--;
        }
        let blogId = this.$route.params.id;
        this.$store.dispatch("ToggleLike", blogId);
      } else {
        this.$store.state.VerfyLogin = true;
      }
    },
    deleteComment(id) {
      let blogId = this.$route.params.id;
      this.$store.dispatch("DeleteComment", id).then((r) => {
        this.$store
          .dispatch("GetBlogsComments", {
            blogId: blogId,
            page: this.$store.state.pagesCount,
          })
          .then((r) => {
            this.BlogComments = this.$store.state.BlogComments;
            this.pagesCount = this.$store.state.pagesCount;
          });
      });
    },
    editComment(item) {
      this.$store.state.updateComment = true;
      this.currentEditedItem = item;
    },
    handleArrayFromChild(array) {
      this.BlogComments = array;
      this.$store.state.updateComment = false;
    },
  },
};
</script>
